import React, { useState, useMemo } from 'react'
import { Input } from '@pancakeswap/uikit'
import styled from 'styled-components'
import debounce from 'lodash/debounce'
import { useTranslation } from 'contexts/Localization'

const StyledInput = styled(Input)`
  border-radius: 16px;
  margin-left: auto;
  color: white;
  background: linear-gradient(90deg, rgb(199, 44, 191) 0%, rgb(197, 44, 191) 6.67%, rgb(192, 43, 192) 13.34%, rgb(181, 42, 194) 20.01%, rgb(168, 41, 196) 26.68%, rgb(154, 40, 199) 33.35%, rgb(136, 38, 202) 40.02%, rgb(118, 37, 205) 46.69%, rgb(99, 35, 208) 53.36%, rgb(81, 34, 211) 60.03%, rgb(63, 32, 214) 66.7%, rgb(49, 31, 217) 73.37%, rgb(36, 30, 219) 80.04%, rgb(25, 29, 221) 86.71%, rgb(20, 28, 222) 93.38%, rgb(18, 28, 222) 100%);
`

const InputWrapper = styled.div`
  position: relative;
  ${({ theme }) => theme.mediaQueries.sm} {
    display: block;
  }
`

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
}

const SearchInput: React.FC<Props> = ({ onChange: onChangeCallback, placeholder = 'Search' }) => {
  const [searchText, setSearchText] = useState('')

  const { t } = useTranslation()

  const debouncedOnChange = useMemo(
    () => debounce((e: React.ChangeEvent<HTMLInputElement>) => onChangeCallback(e), 500),
    [onChangeCallback],
  )

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
    debouncedOnChange(e)
  }

  return (
    <InputWrapper>
      <StyledInput value={searchText} onChange={onChange} placeholder={t(placeholder)} />
    </InputWrapper>
  )
}

export default SearchInput
